*:focus {
  outline: none !important;
  outline: 0;
  box-shadow: none;
}

.app {
  display: flex;
  flex-flow: column wrap;
  text-align: left;
  /* font-size: calc(10px + 2vmin); */
  /* min-height: 100vh; */
  /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
}
.app-header {
  /* height: 40vmin;
  pointer-events: none; */
}
.app-content {
  display: flex;
  flex-flow: row nowrap;
}

.divider {
  flex: 0 0 auto;
  height: 1px;
  background-color: #eee;
  margin: 40px auto 60px;
}
.bg-green {
  background: rgb(104, 187, 147);
}

.btn {
  padding: 0.4rem 1.2rem;
  text-transform: uppercase;
  font-size: 0.8rem;
  border-radius: 25px;
}
.btn:hover,
.btn:focus {
  text-decoration: none;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.btn-info {
  background-color: transparent !important;
  border: 1px solid #fff !important;
  color: #fff !important;
}

.btn-more,
.btn-more.active,
.btn-more:active,
.btn-more:visited {
  background-color: transparent !important;
  border: 1px solid rgb(104, 187, 147) !important;
  color: rgb(104, 187, 147) !important;
}
.btn-more:hover,
.btn-more:focus {
  background: rgb(104, 187, 147);
  border: 1px solid rgb(72, 150, 113);
  color: #fff;
  text-decoration: none;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.btn-order,
.btn-order.active,
.btn-order:active,
.btn-order:visited {
  background: rgb(104, 187, 147) !important;
  border: none !important;
  color: #fff !important;
}
.btn-order:hover,
.btn-order:focus {
  background: rgb(72, 150, 113);
  color: #fff !important;
}

.text-green {
  color: rgb(104, 187, 147);
}
.text-grey {
  color: #555;
}
.text-uppercase {
  text-transform: uppercase;
}

a,
a.active,
a:active,
a:visited {
  color: rgb(104, 187, 147);
}

a:hover,
a:focus {
  color: rgb(72, 150, 113);
  text-decoration: underline;
}

#navbarMain a {
  color: rgb(104, 187, 147);
}
#navbarProducts a {
  color: #fff;
}

a.navbar-brand {
  color: #666;
}

a.navbar-brand:hover {
  text-decoration: none;
}

.navbar-brand-title {
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #666;
  font-weight: 500;
  padding-top: 0.8rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  /* padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color); */
  content: '›';
}

.form-control:focus,
.btn:focus {
  border-color: rgb(104, 187, 147);
  box-shadow: none;
}
.product-card-footer {
  border-top: none;
  background-color: #fff;
}

.product-text p {
  margin-bottom: 0.3rem;
}

@media (max-width: 576px) {
  .carousel-item-img {
    max-width: 90vw !important;
  }
}
